import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      onClick={scrollToTop}
      className="bg-[#F15A24] text-white p-4 rounded-full shadow-lg focus:outline-none hover:bg-[#da5727] transition-colors duration-300"
      title="Volver arriba"
    >
      <FontAwesomeIcon icon={faArrowCircleUp} className="text-2xl" />
    </button>
  );
};

export default ScrollToTopButton;
