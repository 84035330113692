import React from 'react';
import { FaMotorcycle, FaBuilding, FaMapMarkedAlt, FaGlobe } from 'react-icons/fa';

const StatsFeatures = () => {
  const features = [
    {
      icon: <FaMotorcycle className="text-white w-8 h-8 md:w-10 md:h-10" />,
      title: '122 Motorizados',
      description: 'Entrega rápida a través de nuestra red de conductores.',
    },
    {
      icon: <FaBuilding className="text-white w-8 h-8 md:w-10 md:h-10" />,
      title: '300+ Compañías',
      description: 'Confían en nosotros para gestionar sus entregas.',
    },
    {
      icon: <FaMapMarkedAlt className="text-white w-8 h-8 md:w-10 md:h-10" />,
      title: 'Amplia Cobertura',
      description: 'Disponible en Guayaquil y Durán.',
    },
    {
      icon: <FaGlobe className="text-white w-8 h-8 md:w-10 md:h-10" />,
      title: 'API',
      description: 'Integración sencilla con tu sistema de gestión.',
    },
  ];

  return (
    <>
      <h1 className='text-4xl text-[#F15A24] font-bold text-center mt-5'><b>Lo que Delivery Plus te ofrece</b></h1>
    <div className="flex flex-col lg:flex-row justify-between items-stretch mt-6 p-6 bg-white rounded-lg shadow-md">
      {features.map((feature, index) => (
        <div key={index} className="flex mr-6 items-start p-6 bg-gray-100 shadow-lg transition-shadow duration-300 w-full lg:w-auto lg:flex-1 rounded-lg hover:shadow-xl transform hover:scale-105">
          <div className="mr-4 flex items-center justify-center bg-[#F15A24] text-white rounded-full p-3">
            {feature.icon}
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{feature.title}</h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
    
    </>
  );
};
export default StatsFeatures;
