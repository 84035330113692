import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../images/logosApp/logo_d_p_cF.png'; // Asegúrate de que la ruta sea correcta

const SendMessage = ({ isOpen, onRequestClose }) => {
  const [message, setMessage] = useState('');

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const phoneNumber = '+593995167942'; // Reemplaza con el número de teléfono correcto
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    setMessage('');
    onRequestClose(); // Cierra el formulario después de enviar el mensaje
  };

  return (
    isOpen && (
      <div className="fixed bottom-4 right-4 sm:bottom-6 sm:right-6 md:bottom-8 md:right-8 lg:bottom-10 lg:right-10 bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-lg border border-gray-300 z-40 w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl transform transition-transform duration-300 ease-in-out">
        <button
          onClick={onRequestClose}
          className="absolute top-2 right-2 text-gray-500 text-4xl hover:text-gray-800 focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="flex flex-col items-center">
          {/* Imagen siempre arriba */}
          <img
            src={Logo}
            alt="Chatbot"
            className="w-20 h-24 sm:w-5/6 sm:h-30 lg:mb-4 rounded-lg shadow-md"
          />
          <form className="flex flex-col gap-4 w-full max-w-sm lg:max-w-md" onSubmit={sendMessage}>
            <label
              htmlFor="message"
              className="text-lg font-semibold text-gray-700 text-center"
            >
              ¿Cómo podemos ayudarte?
            </label>
            <textarea
              id="message"
              value={message}
              onChange={handleMessageChange}
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#412CC5] transition-transform duration-300 hover:bg-gray-100 w-full resize-none h-24 sm:h-32 lg:h-40"
              placeholder="Escribe tu mensaje aquí"
            />
            <button
              type="submit"
              className="bg-[#412CC5] text-white p-3 rounded-lg hover:bg-[#341aa1] focus:outline-none transition-colors duration-300"
            >
              Enviar mensaje
            </button>
          </form>
        </div>
      </div>
    )
  );
};
export default SendMessage;
