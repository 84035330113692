// NotFoundPage.js
import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white">
      <h1 className="text-9xl font-extrabold text-[#412CC5]">404</h1>
      <p className="mt-4 text-2xl font-semibold text-gray-600">Página no encontrada</p>
      <div className="mt-8 text-xl text-[#F15A24]">
        <p>La página que buscas no está aquí.</p>
        <a href="/" className="mt-4 inline-block px-6 py-3 text-center justify-center bg-[#412CC5] text-white rounded-full shadow-lg hover:bg-[#3a1c9e] transition duration-300">
          Regresar al inicio
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
