import React from 'react';
import Navbar from './home/NavBar';
import Footer from './home/Footer';

const Terminos_Clientes = () => {
  return (
    <>
      <Navbar regresar={true} />
      
      <main className="px-6 py-8">
        <h1 className="text-4xl font-bold  text-center text-[#412CC5] mb-8">
          Términos y Condiciones Clientes
        </h1>
        
        <section className="mb-8">
          <p className="text-gray-700 text-lg text-justify">
          Condiciones generales de uso y contratación de Delivery Plus en clientes
<br />Lea atentamente los términos y condiciones que establecen la relación contractual entre los clientes y Delivery Plus. Este servicio está disponible únicamente para clientes ubicados en la República del Ecuador. Los clientes deberán cumplir con los términos y principios vigentes en Delivery Plus, los cuales están integrados en el acuerdo contractual.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">Primero: Objeto</h2>
          <p className="text-gray-700 text-justify">
          Delivery Plus es una plataforma tecnológica especializada en la gestión y facilitación de servicios de logística y entrega para empresas. Actuamos como intermediarios entre empresas que requieren servicios de logística y repartidores independientes, así como proveedores de productos y servicios. Nuestro servicio se ofrece a través de una aplicación móvil, lo que permite una gestión eficiente y transparente de los procesos logísticos. En ningún caso, los repartidores o proveedores aliados serán considerados empleados de Delivery Plus. Prestan sus servicios de manera independiente y asumen los riesgos correspondientes. Al utilizar nuestros servicios, las empresas clientes y Delivery Plus quedan exentas de cualquier responsabilidad que pueda surgir durante la prestación del servicio logístico. Delivery Plus se reserva el derecho de subcontratar los servicios de entrega a terceros en nombre de las empresas aliadas, siempre y cuando se mantenga la calidad y eficiencia del servicio ofrecido.          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">Segundo: Condiciones de Uso</h2>
          <p className="text-gray-700 text-justify">
          Al utilizar los servicios ofrecidos por Delivery Plus, el usuario reconoce haber leído y comprendido todas las condiciones y términos establecidos en este documento. Al registrarse y utilizar la aplicación móvil, el usuario manifiesta su conformidad y aceptación de estos términos. Cualquier persona que no esté de acuerdo con estos términos deberá abstenerse de utilizar los servicios y la plataforma de Delivery Plus.          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">Tercero: Registro</h2>
          <p className="text-gray-700 text-justify">
          El acceso a la aplicación de Delivery Plus es gratuito, con excepción de los costos asociados al servicio de internet. Los usuarios podrán acceder a la aplicación únicamente a través de los canales autorizados. Aquellos que deseen registrarse como usuarios deberán proporcionar información verídica y actualizada, como nombre de usuario, edad, dirección de correo electrónico, número de teléfono y detalles de pago, asegurando la autenticidad de los mismos.
<br />Delivery Plus no asume responsabilidad por la veracidad de los datos proporcionados y se reserva el derecho de solicitar documentos adicionales que confirmen la información personal. Además, se reserva el derecho de suspender temporal o permanentemente a cualquier usuario cuya identidad no pueda ser confirmada, o ante sospechas de actividad fraudulenta o intentos de suplantación de identidad.
<br />Una vez registrado, Delivery Plus proporcionará al usuario una cuenta personal con contraseña, que deberá ser creada por el propio usuario. Esta cuenta es personal, única e intransferible, y se prohíbe que un mismo usuario registre o posea más de una cuenta. El usuario será el único responsable del uso de su cuenta. Delivery Plus se reserva el derecho de bloquear temporal o permanentemente, cancelar o inhabilitar cuentas en caso de incumplimiento de estas normas u otras políticas de la plataforma.
<br />Los usuarios se comprometen a informar inmediatamente a Delivery Plus en caso de robo, divulgación o pérdida de su nombre de usuario o contraseña, utilizando el formulario de contacto correspondiente u otros medios de comunicación directa habilitados por Delivery Plus.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">Cuarto: Recopilación de Información</h2>
          <p className="text-gray-700 text-justify">
          Los datos personales proporcionados por el usuario en la sección de registro, de acuerdo con los términos y condiciones de Delivery Plus, así como la información suministrada para mejorar el servicio, que puede incluir ubicación, detalles de contacto, información demográfica, dirección de entrega y datos derivados del uso de la plataforma, serán utilizados con el fin de ofrecer la mejor experiencia de servicio al usuario y proporcionar información publicitaria relevante.
<br />Además, Delivery Plus podrá recopilar información indirecta, como cookies, que se pueden eliminar en cualquier momento desde el dispositivo del usuario. Sin embargo, es importante destacar que, al eliminar las cookies, es posible que ciertas funciones de la plataforma no estén disponibles. El usuario tiene la opción de optar por no recibir sugerencias o publicidad en cualquier momento, según lo considere conveniente.
<br />En casos específicos, Delivery Plus podrá solicitar información adicional para procesar pedidos, realizar entregas o verificar la identidad del usuario de manera completa y verídica.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">Quinto: Métodos de Pago</h2>
          <p className="text-gray-700 text-justify">
          El pago de los productos y/o servicios adquiridos presencialmente en los establecimientos asociados, como restaurantes, tiendas, supermercados, farmacias, estaciones de servicio u otros proveedores aliados, y que son entregados a los clientes de forma diferida, será procesado inicialmente por Delivery Plus. Delivery Plus transmitirá este pago a los establecimientos asociados con los que mantiene acuerdos comerciales. Los establecimientos autorizan a Delivery Plus a aceptar el pago en su nombre, por lo que realizar un pago correctamente a favor de Delivery Plus descargará al cliente de la obligación de abonar dicho pago directamente al establecimiento asociado.
          <br />El usuario acepta que Delivery Plus establezca las tarifas por el servicio de entrega, mientras que los precios de los productos vendidos son exclusivamente determinados por los establecimientos asociados. Estas tarifas serán cobradas al cliente por el repartidor en efectivo o a través de pasarelas de pago en línea, según su preferencia.
          <br />Durante el proceso de pago, el cliente será informado del precio final del envío y de la hora aproximada de llegada del pedido.
          <br />Delivery Plus no se hace responsable de quejas relacionadas con los pagos en línea, pérdida o errores en los sistemas adjuntos a la plataforma, ya que esto es responsabilidad de la entidad que brinda el servicio. El cliente debe verificar los datos y tarifas antes de completar el pedido. En caso de que se elija el método de pago en efectivo y el cliente entregue un monto superior al valor total del producto más el servicio de entrega (y, si corresponde, el impuesto correspondiente), el repartidor deberá devolver el cambio al cliente.
          <br />El cliente se compromete a revisar que el monto entregado sea correcto y exonera a Delivery Plus de cualquier inconformidad, responsabilidad o disputa relacionada con el pago. El resumen del pedido completado no constituirá un documento válido para fines tributarios ni servirá como comprobante de pago.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">
            Sexto: Derecho de Desistimiento y Cancelación de Pedidos
          </h2>
          <p className="text-gray-700 text-justify">
          El usuario tiene la opción de cancelar un pedido de forma gratuita siempre y cuando el proveedor no haya procesado la orden debido a la falta de inventario o la indisponibilidad completa del producto o servicio solicitado. Es importante que el usuario entienda que una vez que un proveedor ha aceptado voluntariamente un pedido, se considera que la ejecución de la compra ha comenzado y, por lo tanto, el usuario no podrá cancelar el servicio de forma gratuita.
<br />En el caso de que el usuario cancele un pedido después de que se haya asignado a un proveedor o transportista, se generará un cargo equivalente al 100% del costo del servicio básico de entrega como costo de cancelación. Además, si en el momento de la cancelación el proveedor ya ha realizado la compra de los productos o la contratación del servicio, el usuario no podrá solicitar la devolución de los mismos. En este caso, el usuario deberá cubrir el costo total de los productos adquiridos y los gastos de entrega.
<br />Delivery Plus se reserva el derecho de cancelar un pedido sin necesidad de justificación. En caso de que la cancelación sea iniciada por Delivery Plus, el usuario tendrá derecho al reembolso total del monto pagado.
</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">
          Séptimo: Condiciones Generales de Uso de la Plataforma Web y Aplicación Móvil de Delivery Plus          </h2>
          <p className="text-gray-700 text-justify">
          El usuario se compromete a utilizar la plataforma de manera lícita y adecuada, y a respetar los términos y condiciones establecidos por Delivery Plus, garantizando su conformidad con el acuerdo. Al utilizar la aplicación, el usuario acepta:
          </p>  
          <ul className="list-disc list-inside text-gray-700 mt-4">
            <li>Ser responsable de mantener segura y confidencial su contraseña de cuenta, así como cualquier identificación o medio que permita el acceso a la plataforma.</li>
            <li>No transferir ni ceder su cuenta registrada a ninguna persona sin la autorización de Delivery Plus, ni permitir que terceros utilicen la cuenta.</li>
            <li>Utilizar el servicio de manera lícita y de buena fe, sin incurrir en actividades fraudulentas o que infrinjan los derechos de terceros.</li>
            <li>Reconocer a Delivery Plus como un mero intermediario entre el proveedor y el usuario que requiere el servicio de entrega.</li>
            <li>Aceptar que, al realizar operaciones con el proveedor, lo hace bajo su propio riesgo.</li>
            <li>Abstenerse de acceder a recursos restringidos en la plataforma, manipular datos, introducir virus informáticos u otros actos que puedan dañar la plataforma.</li>
            <li>Reconocer que Delivery Plus no se hace responsable por el contenido publicado por terceros ni por la conducta de otros usuarios.</li>
            <li>Prohibir la publicación de imágenes o contenido que vulnere la sensibilidad humana o que pueda afectar la integridad de las personas.</li>
            <li>Aceptar que, en caso de no cancelar un pedido, se generará un cargo adicional del 10% del valor total de la operación, con plazo indefinido hasta su cancelación total.</li>
          </ul>
          <p>
          Delivery Plus se reserva el derecho de emplear medios legales, bloquear temporal o permanentemente a usuarios registrados o externos en caso de incumplimiento de una o más normas en las condiciones generales de uso de la plataforma y el acuerdo con el usuario.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">
            Octavo: Política de Envíos con Materias Restringidas
          </h2>
          <p className="text-gray-700 text-justify">
          Delivery Plus se reserva el derecho de no enviar los siguientes tipos de materiales (esta lista no es exhaustiva):          </p>
          <ul className="list-disc list-inside text-gray-700 mt-4">
            <li>Alcohol o tabaco, dependiendo de la edad del usuario y las leyes nacionales vigentes, como el estado de excepción o la ley seca.</li>
            <li>Partes de animales, plantas nocivas o en peligro de extinción, y cualquier otro material relacionado.</li>
            <li>Material pornográfico.</li>
            <li>Copias no autorizadas de libros, videojuegos, películas, música u otro material protegido por derechos de autor.</li>
            <li>Sustancias controladas, narcóticos, drogas ilegales y cualquier otro producto prohibido por la ley.</li>
            <li>Órganos u otras partes del cuerpo, fluidos corporales, células madre, embriones u otros materiales biológicos.</li>
            <li>Bienes robados o ilegales, así como cualquier material que facilite actividades ilegales o relacionadas con el crimen.</li>
            <li>Armas de fuego, municiones u otros dispositivos relacionados.</li>
            <li>Divisas, dinero en efectivo, metales preciosos o cualquier otro título de valor que esté prohibido enviar.</li>
            <li>Delivery Plus se reserva el derecho de rechazar el envío de cualquier material que considere inapropiado o que viole estas políticas.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">
            Noveno: Independencia de las Cláusulas
          </h2>
          <p className="text-gray-700 text-justify">
          En el caso de que alguna disposición de los Términos y Condiciones resulte nula, inexistente o ilegal, la validez de las demás disposiciones no se verá afectada ni limitada de ninguna manera. Esto no afectará la validez total o parcial de los Términos y Condiciones, ni el derecho a emprender acciones legales o contractuales con respecto a las disposiciones restantes.
          </p>
          </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">
            Décimo: Derechos de Propiedad Industrial
          </h2>
          <p className="text-gray-700 text-justify">
          El usuario reconoce y acepta que todos los contenidos (imágenes, lemas comerciales, logotipos, nombres, diagramas, bases de datos, software, sonidos, diseños, audio, video, marcas, apodos, títulos, hardware) y cualquier otro derecho de propiedad intelectual e industrial, independientemente de su naturaleza, pertenecen y son propiedad de Delivery Plus y están publicados en las plataformas web, móvil u otros medios de difusión.
<br />Delivery Plus autoriza al usuario a almacenar, compartir, visualizar y descargar elementos y contenidos dentro de las plataformas únicamente con fines no comerciales ni de alteración, sin la necesidad de autorización previa.
<br />El lema comercial "Siempre dando una milla de esfuerzo más" y "No importa la ubicación, solo tu satisfacción" se refiere al esfuerzo constante que hacemos para ofrecer la mejor experiencia al usuario, y no implica un aumento del recorrido sin costo adicional.
</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">
            Undécimo: Legislación Aplicable
          </h2>
          <p className="text-gray-700 text-justify">
          La relación entre el usuario y Delivery Plus se regirá de acuerdo con las cláusulas anteriores, las cuales en materia de interpretación, validez y ejecución estarán sujetas a la legislación vigente en el país correspondiente.
          </p>
          </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#F15A24] mb-4">
            Duodécimo: Resolución Extrajudicial de Conflictos
          </h2>
          <p className="text-gray-700 text-justify">
          <br />12.1. Las partes se comprometen a resolver cualquier desacuerdo que pueda surgir durante la ejecución del presente contrato de manera amistosa.
<br />12.2. En caso de controversias, las partes acuerdan someterse al fallo que emitan los árbitros del Centro de Arbitraje y Mediación de la Cámara de Comercio de Guayaquil, provincia del Guayas, ciudad de Guayaquil, República del Ecuador, de acuerdo con lo establecido en la Ley de Arbitraje y Mediación, al Reglamento del Centro de Arbitraje y Mediación de las Cámaras de Comercio, y las siguientes normas:
{/* listas de letras a,b c */}
          <ul className="list-none list-inside text-gray-700 mt-4">
            <li>a) Los árbitros serán seleccionados conforme a lo establecido en la Ley de Arbitraje y Mediación. </li>
            <li>b) Las partes renuncian a su derecho de acudir a la jurisdicción ordinaria y se comprometen a acatar el laudo emitido por el Tribunal Arbitral. </li>
            <li>c) El Tribunal Arbitral tiene la facultad de solicitar el auxilio de funcionarios públicos judiciales, policiales y administrativos para la ejecución de medidas cautelares, sin necesidad de recurrir a un juez ordinario. </li>
            <li>d) El Tribunal estará integrado por UN (1) árbitro. </li>
            <li>e) El procedimiento arbitral será confidencial. </li>
            <li>f) Los árbitros decidirán en derecho. </li>
            <li>g) El lugar del arbitraje será en las instalaciones del Centro de Arbitraje y Mediación de las Cámaras de Comercio.</li>
          </ul>
          <br />12.3. En caso de incumplimiento del presente contrato por una de las partes, según corresponda, la parte afectada podrá tomar las acciones legales pertinentes en caso de existir daños o perjuicios entre las partes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-bold text-[#412CC5] mb-6">
            Vigencia del Acuerdo
          </h2>
          <p className="text-gray-700 text-lg text-justify">
          El presente acuerdo entre el usuario y Delivery Plus en los Términos y Condiciones está vigente en la República del Ecuador y cualquier ajuste o modificación se regirá por las leyes de dicho país. Al utilizar nuestros servicios, los usuarios y visitantes aceptan haber leído este documento y estar de acuerdo con todos los Términos y Condiciones.
<br />Delivery Plus se reserva el derecho de terminar, actualizar o modificar los términos y condiciones en cualquier momento. Se recomienda a los usuarios revisar periódicamente estos términos para asegurarse de estar al tanto de cualquier cambio.
</p>
        </section>
      </main>
      
      <Footer />
    </>
  );
};

export default Terminos_Clientes;
