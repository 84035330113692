import React from "react";
import "../../../css/CarouselClients.css"; // Importa el archivo CSS donde definiste las animaciones
import BurgerKing from '../../images/logoNegocios/burger-king.gif';
import KFCLogo from '../../images/logoNegocios/KFC-logo.png';
import ChauLaoLogo from '../../images/logoNegocios/chau-lao-logo.jpg';
import DBebesLogo from '../../images/logoNegocios/logo-dbebes.svg';
import MenestrasDelNegroLogo from '../../images/logoNegocios/menestras-del-nego-logo.jpg';
import cinnabonLogo from '../../images/logoNegocios/cinnabon-logo.png';
import brLogo from '../../images/logoNegocios/br-logo.png';
import americanDeliLofo from '../../images/logoNegocios/american-deli-logo.png';
import elEspanolLogo from '../../images/logoNegocios/el-espanol-logo.svg';
import polloGusLogo from '../../images/logoNegocios/pollo-gus-logo.png';
import ilCappoLogo from '../../images/logoNegocios/il-cappo-logo.png';
import cajunLogo from '../../images/logoNegocios/cajun-logo.png';
import juanValdezLogo from '../../images/logoNegocios/juan-valdez-logo.jpg';

const logos = [
    BurgerKing, KFCLogo, ChauLaoLogo, DBebesLogo, MenestrasDelNegroLogo, cinnabonLogo,
    brLogo, americanDeliLofo, elEspanolLogo, polloGusLogo, ilCappoLogo, cajunLogo, juanValdezLogo
];

const CarouselClients = () => {
    return (
        <div className="flex flex-col items-center justify-center shadow-sm p-8 bg-white mt-8 max-w-screen-2xl mx-auto">
            <h1 className="text-4xl font-bold text-[#412CC5] text-center"><b>Nuestros Clientes</b></h1>
            <p className="text-[#F15A24] text-xl text-center mt-4">¡Únete a ellos y experimenta los beneficios tú también!</p>
            <div className="carousel-wrapper mt-8 overflow-hidden">
                <div className="carousel-content flex">
                    {logos.concat(logos).map((logo, index) => (
                        <div key={index} className="logo-container flex items-center justify-center">
                            <img src={logo} alt="Logo" className="logo" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CarouselClients;
