import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMotorcycle, faCarSide, faBolt, faRulerHorizontal, faRulerVertical, faWeightScale } from '@fortawesome/free-solid-svg-icons';
import motorizadoImg1 from '../../images/deverlyInst/motorizado_1.jpg';
import motorizadoImg2 from '../../images/deverlyInst/motorizado_2.jpg';
import carroImg1 from '../../images/deverlyInst/carro_1.jpg';
import carroImg2 from '../../images/deverlyInst/carro_2.jpg';

const ComparisonComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const handleOpenModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVehicle(null);
  };

  const getVehicleImages = (vehicle) => {
    if (vehicle === 'motorizado') {
      return [motorizadoImg1, motorizadoImg2];
    } else if (vehicle === 'carro') {
      return [carroImg1, carroImg2];
    }
    return [];
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center bg-gradient-to-b from-gray-100 to-gray-300 p-6 md:p-8 lg:p-12">
      {/* Contenedor Comparativo */}
      <div className="bg-white shadow-2xl rounded-2xl w-full max-w-6xl p-4 md:p-6 lg:p-8">
        <div className="flex flex-col md:flex-row items-center overflow-hidden w-full">
          
          {/* Motorizado */}
          <div className="flex-1 flex flex-col items-center p-4 md:p-6 hover:bg-[#F0F4FF] transition-all duration-300 rounded-lg">
            <FontAwesomeIcon icon={faMotorcycle} className="text-[#412CC5] w-32 h-32 mb-4" />
            <h3 className="text-2xl md:text-3xl font-extrabold text-[#412CC5] mb-4">Motorizado</h3>
            <ul className="text-gray-700 text-left text-base md:text-lg">
              <li className="mb-2"><strong>Tiempo de espera:</strong> Rápido</li>
              <li className="mb-2"><strong>Capacidad de carga:</strong> Baja</li>
              <li className="mb-2"><strong>Precio:</strong> Económico</li>
              <li><strong>Flexibilidad:</strong> Alta</li>
            </ul>
            <button onClick={() => handleOpenModal('motorizado')} 
              className="text-white bg-[#412CC5] px-4 py-2 font-bold mt-5 rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:bg-[#da5727] shadow-md hover:shadow-xl duration-300"
              >
              Ver detalles
            </button>
          </div>

          {/* Divisor */}
          <div className="hidden md:block border-l-2 border-gray-200 h-full mx-4"></div>

          {/* Círculo con íconos */}
          <div className="relative flex items-center justify-center mx-4 md:mx-8 my-6 md:my-0">
            <div className="w-48 h-48 bg-gradient-to-r from-[#F15A24] to-[#412CC5] rounded-full flex items-center justify-center shadow-2xl relative">
              <FontAwesomeIcon icon={faMotorcycle} className="text-white w-12 h-12 mr-3" />
              <FontAwesomeIcon icon={faCarSide} className="text-white w-12 h-12 ml-3" />
            </div>
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <FontAwesomeIcon icon={faBolt} className="text-yellow-500 animate-ping w-10 h-10" />
            </div>
          </div>

          {/* Divisor */}
          <div className="hidden md:block border-l-2 border-gray-200 h-full mx-4"></div>

          {/* Carro */}
          <div className="flex-1 flex flex-col items-center p-4 md:p-6 hover:bg-[#FFF5F0] transition-all duration-300 rounded-lg">
            <FontAwesomeIcon icon={faCarSide} className="text-[#F15A24] w-32 h-32 mb-4" />
            <h3 className="text-2xl md:text-3xl font-extrabold text-[#F15A24] mb-4">Carro</h3>
            <ul className="text-gray-700 text-left text-base md:text-lg">
              <li className="mb-2"><strong>Tiempo de espera:</strong> Moderado</li>
              <li className="mb-2"><strong>Capacidad de carga:</strong> Alta</li>
              <li className="mb-2"><strong>Precio:</strong> Moderado</li>
              <li><strong>Flexibilidad:</strong> Media</li>
            </ul>
            <button onClick={() => handleOpenModal('carro')} 
              className="text-white bg-[#F15A24] px-4 py-2 font-bold mt-5 rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:bg-[#412CC5] shadow-md hover:shadow-xl duration-300"
              >
              Ver detalles
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-4 md:p-6 lg:p-8 w-full max-w-lg relative">
            <button onClick={handleCloseModal} className="absolute top-2 right-2 text-gray-500 text-4xl hover:text-gray-800">&times;</button>
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-center">{`Características del espacio en el ${selectedVehicle === 'motorizado' ? 'Motorizado' : 'Carro'}`}</h2>
            <div className="flex justify-center mb-4">
              <img src={getVehicleImages(selectedVehicle)[0]} alt={`${selectedVehicle} 1`} className="w-32 h-32 object-cover rounded-md mr-2" />
              <img src={getVehicleImages(selectedVehicle)[1]} alt={`${selectedVehicle} 2`} className="w-32 h-32 object-cover rounded-md" />
            </div>
            <table className="w-full text-left text-base md:text-lg">
              <tbody>
                <tr>
                  <td className="py-2 flex items-center justify-center">
                    <FontAwesomeIcon icon={faRulerHorizontal} className="mr-2 text-xl text-[#412CC5]" />
                    <strong className='mr-4'>Largo:</strong>
                  </td>
                  <td className="py-2 text-center">{selectedVehicle === 'motorizado' ? '50 cm' : '4.5 m'}</td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center justify-center">
                    <FontAwesomeIcon icon={faRulerVertical} className="mr-2 text-xl text-[#412CC5]" />
                    <strong>Ancho:</strong>
                  </td>
                  <td className="py-2 text-center">{selectedVehicle === 'motorizado' ? '45 cm' : '1.8 m'}</td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center justify-center">
                    <FontAwesomeIcon icon={faRulerVertical} className="mr-2 text-xl text-[#412CC5]" />
                    <strong>Altura:</strong>
                  </td>
                  <td className="py-2 text-center">{selectedVehicle === 'motorizado' ? '35 cm' : '1.6 m'}</td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center justify-center">
                    <FontAwesomeIcon icon={faWeightScale} className="mr-2 text-xl text-[#412CC5]" />
                    <strong className='mr-4'>Peso:</strong>
                  </td>
                  <td className="py-2 text-center">{selectedVehicle === 'motorizado' ? '4 lb' : '1.5 toneladas'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparisonComponent;
