import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import backgroundImage from '../../images/home/font.png';
import chica from '../../images/home/chica.png';
const words = ['seguros', 'rápidos', 'eficientes', 'confiables'];

const HomeScreen = () => {
  const [index, setIndex] = useState(0);

  const fadeStyles = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    reset: true,
    config: { duration: 1000 },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <div
      className="w-full overflow-hidden bg-cover bg-center flex flex-col md:flex-row items-center justify-center md:justify-between pt-20"
      style={{ backgroundImage: `url(${backgroundImage})`, height: '70vh' }}
    >
      <div className="text-center md:text-left flex-1 p-6 md:p-8 lg:p-10">
        <h2 className="text-white text-2xl md:text-3xl lg:text-5xl font-bold leading-tight">
          Tus productos nunca han viajado más{' '}
          <animated.span style={fadeStyles} className="text-[#F15A24]">
            {words[index]}
          </animated.span>
        </h2>
      </div>
      <div className="flex-shrink-0 md:p-0 self-end">
        <img
          src={chica}
          alt="Moto"
          className="object-contain w-full md:w-3/4 lg:w-auto max-w-none md:max-w-md lg:max-w-lg xl:max-w-2xl"
        />
      </div>
    </div>
    </>
  );
};

export default HomeScreen;
