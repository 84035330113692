import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCashRegister, faHeadset, faTruck, faMoneyBillWave, faEnvelopeOpenText, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const DeliveryFeatures = () => {
  const features = [
    {
      icon: faEnvelopeOpenText,
      title: 'Mensajería Masiva',
      description: 'Impulsa la efectividad de tus comunicaciones con un servicio de mensajería que garantiza rapidez y precisión en la entrega de tus mensajes.',
    },
    {
      icon: faChartLine,
      title: 'Crecimiento Acelerado',
      description: 'Potencia tu negocio con soluciones de entrega que incrementan tus ventas y mejoran la satisfacción del cliente en tiempos récord.',
    },
    {
      icon: faCashRegister,
      title: 'Facturación Simplificada',
      description: 'Optimiza tus procesos de cobro con nuestra plataforma que te permite facturar entregas de forma sencilla y eficiente.',
    },
    {
      icon: faBullhorn,
      title: 'Asesoría Personalizada',
      description: 'Recibe asesoramiento experto para expandir y optimizar tu servicio de delivery, asegurando una experiencia excepcional para tus clientes.',
    },
    {
      icon: faHeadset,
      title: 'Soporte Call Center',
      description: 'Disponemos de un Call Center dedicado, listo para brindar a tus clientes una atención rápida y personalizada, las 24 horas del día.',
    },
    {
      icon: faTruck,
      title: 'Servicios de Delivery Adicionales',
      description: 'Ofrecemos una gama de servicios adicionales que complementan el delivery tradicional, adaptándose a las necesidades específicas de tu negocio.',
    },
    {
      icon: faMoneyBillWave,
      title: 'Pago Contra Entrega',
      description: 'Ofrecemos la opción de pago contra entrega para mayor comodidad y seguridad, facilitando las transacciones tanto para ti como para tus clientes.',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,  // 3 segundos
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="p-6 bg-gray-100">
      <Slider {...settings}>
        {features.map((feature, index) => (
          <div key={index} className="p-4">
          <div className="flex flex-col items-center justify-between bg-white border-2 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 h-96 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-[#412CC5] to-[#F15A24]"></div>
            <FontAwesomeIcon icon={feature.icon} className="text-[#412CC5] w-24 h-24 mt-6 mb-4 p-2" />
            <div className="bg-[#412CC5] text-white w-full text-center py-3 font-bold uppercase z-10">
              {feature.title}
            </div>
            <p className="text-gray-800 text-justify px-6 mt-4 mb-6 flex-grow">
              {feature.description}
            </p>
            <div className="absolute bottom-0 left-0 w-full h-4 bg-gradient-to-r from-[#412CC5] to-[#F15A24]"></div>
          </div>
        </div>
        
        ))}
      </Slider>
    </div>
  );
};


export default DeliveryFeatures;
