import React from "react";

const SendMessagesWhatp = ({ message, sendlocation = false, location = null }) => {
  const baseUrl = "https://wa.me/593995167942?text=";
  if (sendlocation) {
    const menssageWithLocation = () => {
        let m = message;
        m += `\nUbicación en el mapa:\n https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;

        return m;
        };
    return (
        <div>
          <a 
            className="text-white bg-[#F15A24] px-4 py-2 md:px-6 md:py-3 font-bold rounded-lg transition-transform transform hover:scale-105 hover:bg-[#da5727] hover:shadow-lg shadow-md duration-300 flex items-center justify-center"
            href={`${baseUrl}${encodeURIComponent(menssageWithLocation())}`}
            target="_blank"
            rel="noreferrer"
          >
            Enviar mensaje
          </a>
        </div>
      );
  }
    return (
        <div>
        <a
            className="text-white bg-[#F15A24] px-4 py-2 md:px-6 md:py-3 font-bold rounded-lg transition-transform transform hover:scale-105 hover:bg-[#da5727] hover:shadow-lg shadow-md duration-300 flex items-center justify-center"
            href={`${baseUrl}${encodeURIComponent(message)}`}
            target="_blank"
            rel="noreferrer"
        >
            Enviar mensaje
        </a>
        </div>
    );
};

export default SendMessagesWhatp;
