import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/pages/Home';
import AfiliatedCommerce from './components/pages/forms/AfiliatedCommerce';
import AfiliatedMororized from './components/pages/forms/AfiliatedMotorized';
import NotFoundPage from './components/pages/errors/NotFoundPage';
import Terminos_Aliados from './components/pages/Terminos_Aliados';
import Terminos_Clientes from './components/pages/Terminos_Clientes';
import FirstDeliveryForm from './components/pages/forms/FirstDeliveryForm';

const AppContent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/form-afiliated-commerce' element={<AfiliatedCommerce />} />
        <Route path='/form-afiliated-motorized' element={<AfiliatedMororized />} />
        <Route path='/terminos-aliados' element={<Terminos_Aliados />} />
        <Route path='/terminos-clientes' element={<Terminos_Clientes />} />
        <Route path='/from-mi-primer-delivery' element={<FirstDeliveryForm />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      {/* from-mi-primer-delivery */}
    </BrowserRouter>
  );
};


function App() {
  return (
    <AppContent />
  );
}

export default App;
