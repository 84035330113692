import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import contact from "../../images/deverlyInst/contact.jpeg";

const Contact = () => {
    return (
        <section className="w-full mt-16 flex flex-col md:flex-row items-center bg-white py-8 px-4 shadow-lg rounded-lg">
            {/* Imagen de contacto */}
            <div className="relative w-full md:w-1/2 flex justify-center mb-6 md:mb-0">
                <img 
                    src={contact} 
                    alt="Contacto" 
                    className="w-full h-3/4 md:h-1/2 object-cover rounded-lg transition-transform duration-300 hover:scale-105 max-w-md md:max-w-lg shadow-md"
                />  
            </div>
            {/* Información de contacto */}
            <div className="w-full md:w-1/2 px-4 flex flex-col items-center">
                <div className="contact-info text-center">
                    <h1 className="text-4xl font-extrabold mt-2 text-gray-800">
                        <b>Información de contacto</b>
                    </h1>
                    
                    <div className="mt-4">
                        <h3 className="text-lg font-semibold flex items-center justify-center text-gray-700">
                            <FaMapMarkerAlt className="mr-2 text-[#F15A24] text-2xl" />
                            Dirección
                        </h3>
                        <p className="text-base mt-2 text-gray-600 text-center">
                            Mucho lote 1
                        </p>
                        <div className="mt-2">
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15948.611293326996!2d-79.9189437!3d-2.094471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d13b016083ce7%3A0xc2fccf969b10200d!2sMucho%20lote%201!5e0!3m2!1ses-419!2sec!4v1724694719751!5m2!1ses-419!2sec" 
                                className="w-full h-80 object-cover rounded-lg shadow-md transition-transform duration-300 hover:scale-105 max-w-md md:max-w-lg"
                                allowFullScreen="" 
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                    
                    <div className="mt-6">
                        <h3 className="text-lg font-semibold flex items-center justify-center text-gray-700">
                            <FaPhoneAlt className="mr-2 text-[#F15A24] text-2xl" />
                            Teléfono
                        </h3>
                        <p className="text-base mt-2 text-gray-600 text-center">
                            <a href="tel:+593995167942" className="text-[#F15A24] hover:underline">
                                +593 098 765 4321
                            </a>
                        </p>
                    </div>
                    
                    <div className="mt-4">
                        <h3 className="text-lg font-semibold flex items-center justify-center text-gray-700">
                            <FaEnvelope className="mr-2 text-[#F15A24] text-2xl" />
                            Email
                        </h3>
                        <p className="text-base mt-2 text-gray-600 text-center">
                            <a href="mailto:deliveryplusec@gmail.com" className="text-[#F15A24] hover:underline">
                                deliveryplusec@gmail.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
