import React from "react";
import "../../../css/CubeComponent.css"; // Asegúrate de que este archivo contiene los estilos CSS necesarios

const CubeComponent = () => {
  return (
    <div className="cube-container">
      <div className="cube">
        <div className="top">
          <div className="content">
            D
            <div className="plus">+</div>
          </div>
          
        </div>
        <div className="box">
          <span style={{ '--i': 0 }}></span>
          <span style={{ '--i': 1 }}></span>
          <span style={{ '--i': 2 }}></span>
          <span style={{ '--i': 3 }}></span>
        </div>
      </div>
    </div>
  );
};

export default CubeComponent;
