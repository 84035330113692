import React from "react";
import medicinas from "../../images/deverlyInst/medicinas.jpg";
import aeropuerto from "../../images/deverlyInst/aeropuerto.jpeg";
import documentos from "../../images/deverlyInst/documentos.jpeg";
import b2b from "../../images/deverlyInst/b2b.jpg";
import comida from "../../images/deverlyInst/comida.jpg";
import compras from "../../images/deverlyInst/compras.jpg";
import '../../../css/CardComponent.css';

import { FaMedkit as IconMedicinas, 
        FaPlane as IconAeropuerto,
        FaUserTie as IconB2B,
        FaDochub as IconDocumentos,
        FaShoppingCart as IconCompras,
        FaUtensils as IconComida
} from "react-icons/fa";

const WhyPreferme = () => {
    const services = [
        {
            imgSrc: medicinas,
            iconSrc: <IconMedicinas size={36} className="text-[#412CC5]" />,
            title: "Medicinas",
            description: "Realiza tus pedidos de medicinas y recíbelos directamente en la puerta de tu hogar, asegurando que nunca te falte lo que necesitas para tu bienestar."
        },
        {
            imgSrc: documentos,
            iconSrc: <IconDocumentos size={36} className="text-[#412CC5]" />,
            title: "Documentos",
            description: "Envía y recibe documentos de manera rápida y confiable, eliminando el estrés de la logística y garantizando que tus comunicaciones sean siempre puntuales."
        },
        {
            imgSrc: aeropuerto,
            iconSrc: <IconAeropuerto size={36} className="text-[#412CC5]" />,
            title: "Aeropuertos",
            description: "Entregamos tus paquetes en los aeropuertos de Guayaquil, Quito y Cuenca, facilitando tus viajes y asegurando que tus envíos lleguen a tiempo, sin importar la distancia."
        },
        {
            imgSrc: b2b,
            iconSrc: <IconB2B size={36} className="text-[#412CC5]" />,
            title: "Servicios B2B",
            description: "Ofrecemos envíos express de tus productos a tus clientes, garantizando una entrega rápida y eficiente que fortalecerá tu relación comercial y mejorará la satisfacción del cliente."
        },
        {
            imgSrc: comida,
            iconSrc: <IconComida size={36} className="text-[#412CC5]" />,
            title: "Comida",
            description: "Disfruta de una amplia variedad de opciones gastronómicas, entregadas directamente a tu puerta. Desde platos locales hasta delicias internacionales, garantizamos que cada bocado sea una experiencia inolvidable."
        },
        {
            imgSrc: compras,
            iconSrc: <IconCompras size={36} className="text-[#412CC5]" />,
            title: "Compras",
            description: "Realiza tus compras sin salir de casa. Te ofrecemos un servicio de entrega eficiente y seguro, asegurando que tus productos lleguen a ti en perfectas condiciones y a tiempo, para que disfrutes de lo que más te gusta sin complicaciones."
        }
        
    ];

    return (
        <section id="services" className="w-full bg-[#412CC5] py-16 px-6 lg:px-8">
            <div className="container mx-auto px-4 lg:px-8 text-center">
                <h4 className="text-white text-4xl md:text-4xl lg:text-6xl font-bold uppercase mb-4"><b>¿Qué ofrecemos?</b></h4>
                <h1 className="text-white text-2xl md:text-3xl lg:text-4xl font-extrabold mb-12">
                    Servicios Delivery <span className="text-[#F15A24]">Plus</span>
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 max-w-screen-lg mx-auto">
                    {services.map((service, index) => (
                        <div 
                            key={index} 
                            className="relative flex flex-col items-center border-white border-2 h-64 bg-white rounded-lg shadow-xl overflow-hidden transform transition-transform duration-300 hover:bg-[#F15A24] group"
                        >
                            <div className="w-full h-full"> 
                                <picture>
                                    <source srcSet={`${service.imgSrc}`} type="image/webp" />
                                    <img 
                                        src={service.imgSrc} 
                                        alt={service.title} 
                                        className="w-full h-full object-cover" 
                                    />
                                </picture>
                                <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-50">
                                    {service.iconSrc}
                                    <h3 className="text-lg sm:text-xl font-semibold text-[#412CC5]">{service.title}</h3>
                                </div>
                            </div>
                            <div className="absolute inset-0 flex flex-col items-center justify-center bg-[#F15A24] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                <div className="flex items-center mb-1">
                                    <p className="text-white text-sm sm:text-base text-justify mb-4 mx-6">{service.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhyPreferme;
