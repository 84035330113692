import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faUser, faPhone, faMapMarkerAlt, faCheckCircle, faWarning, faPeopleCarryBox } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import Navbar from '../home/NavBar';
import Footer from '../home/Footer';
import MapComponent from './MapComponent';
import SendMenssage from './SendMenssage'; // Corregido el nombre del import
import SendMessagesWhatp from '../../functions/SendMessagesWhatp';

const FirstDeliveryForm = () => {
  const [formData, setFormData] = useState({
    product: '',
    local: '',
    name: '',
    contact: '',
    location: '',
    nameLocation: '',
  });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // La lógica de envío ahora se maneja en el componente SendMenssage
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSelectLocation = (location) => {
    setSelectedLocation(location);
    setFormData({
      ...formData,
      location: `${location.lat}, ${location.lng}`,
    });
    closeModal();
  };

  const isFormValid = () => {
    return formData.product && formData.name && formData.contact && formData.location && formData.local && formData.nameLocation;
  };

  const getMessage = () => {
    return `*Realizar mi primer delivery*\n\n*Producto:* ${formData.product}\n*Nombre:* ${formData.name}\n*Contacto:* ${formData.contact}\nLocal: ${formData.local}\nUbicación del local: ${formData.nameLocation}\n*Coordenadas:* ${formData.location}`;
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '600px',
    },
  };

  return (
    <>
      <Navbar regresar={true}/>
      <div className="flex justify-center items-center min-h-screen bg-[#412CC5] py-8 px-4 sm:px-6 lg:px-8">
        <form className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4" onSubmit={handleSubmit}>
          <h1 className="text-2xl sm:text-3xl font-bold text-[#412CC5] text-center mb-6">
            <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
            Formulario para solicitar delivery
          </h1>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Producto</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faBoxOpen} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="product"
                value={formData.product}
                onChange={handleChange}
                className="w-full outline-none"
                placeholder="¿Qué producto quieres pedir?"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Local</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faPeopleCarryBox} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="local"
                value={formData.local}
                onChange={handleChange}
                className="w-full outline-none"
                placeholder="Nombre del local"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Nombre de la persona</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faUser} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full outline-none"
                placeholder="Nombre completo"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Contacto</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faPhone} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                className="w-full outline-none"
                placeholder="Número de contacto"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 font-semibold">Ubicación</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="nameLocation"
                value={formData.nameLocation}
                onChange={handleChange}
                className="w-full outline-none"
                placeholder="Ubicación de entrega"
                required
              />
            </div>
            <span>Escribe una referencia de la entrega</span>
          </div>
          {selectedLocation ? (
            <>
              <p className="mb-4">
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" /> Ubicación seleccionada
              </p>
              <button
                type="button"
                onClick={openModal}
                className="w-full bg-[#412CC5] text-white p-2 mb-4 rounded-lg hover:bg-[#2d1f7c]"
              >
                Seleccionar otra Ubicación
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={openModal}
                className="w-full bg-[#c52c43] text-white p-2 mb-4 rounded-lg"
              >
                Seleccionar en Mapa
              </button>
              <p className="text-center text-[#ee800b]">
                <FontAwesomeIcon icon={faWarning} className="mr-2" />
                No has seleccionado una ubicación en el mapa
              </p>
            </>
          )}
          {(isFormValid() && selectedLocation) ? (
            <SendMessagesWhatp message={getMessage()} sendlocation={true} location={selectedLocation}/>
          ) : (
            <p className="text-red-500 text-center">Por favor, completa todos los campos.</p>
          )}
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Selecciona una ubicación"
      >
        <h2 className="text-center font-bold text-[#412CC5] mb-4">Selecciona una Ubicación</h2>
        <MapComponent onSelectLocation={handleSelectLocation} />
        <div className="flex justify-center mt-4 gap-2">
          <button
            onClick={closeModal}
            className="bg-[#412CC5] text-white p-2 rounded-lg hover:bg-[#2d1f7c]"
          >
            Cerrar
          </button>
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default FirstDeliveryForm;