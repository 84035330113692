import React, { useState } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMotorcycle, faUser, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../home/NavBar";
import Footer from "../home/Footer";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const AfiliatedMororized = () => {
  const [formData, setFormData] = useState({
    nombres: "",
    apellidos: "",
    telefono: "",
    ubicacion: "GUAYAQUIL",
  });
  const [phoneError, setPhoneError] = useState(false);

  const isFormValid = () => {
    return formData.nombres && formData.apellidos && formData.ubicacion !== ""  && isValidPhoneNumber(formData.telefono);
  };

  const getMessage = () => {
    return `Hola, quiero afiliarme a Delivery Plus
    \nNombres: ${formData.nombres}
    \nApellidos: ${formData.apellidos}
    \nTeléfono: ${formData.telefono}
    \nUbicación: ${formData.ubicacion}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, telefono: value || '' });
    setPhoneError(!isValidPhoneNumber(value || ''));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      console.log("Formulario enviado");
    } else {
      alert("Por favor, completa todos los campos.");
    }
  };

  const handleSelectUbicacion = (e) => {
    const selectedUbicacion = e.target.value;
    setFormData({ ...formData, ubicacion: selectedUbicacion });
  };



  return (
    <>
      <Navbar regresar={true} />
      <div className="flex justify-center items-center min-h-screen bg-[#412CC5] py-8 px-4 sm:px-6 lg:px-8">
        <form className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4" onSubmit={handleSubmit}>
          <h1 className="text-2xl sm:text-3xl font-bold text-[#412CC5] text-center mb-6">
            <FontAwesomeIcon icon={faMotorcycle} className="mr-2" />
            Afíliame a Delivery Plus
          </h1>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Nombres</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faUser} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="nombres"
                value={formData.nombres}
                onChange={handleInputChange}
                className="w-full outline-none"
                placeholder="Nombres"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Apellidos</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faUser} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="apellidos"
                value={formData.apellidos}
                onChange={handleInputChange}
                className="w-full outline-none"
                placeholder="Apellidos"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Teléfono</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faPhone} className="text-[#412CC5] mr-2" />
              <PhoneInput
                international
                defaultCountry="EC"
                value={formData.telefono}
                onChange={handlePhoneChange}
                className={`w-full outline-none ${phoneError ? 'border-red-500' : 'border-gray-300'}`}
              />
            </div>
            {phoneError && <p className="text-red-500 text-sm mt-1">Número de teléfono inválido</p>}
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 font-semibold">Ubicación</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#412CC5] mr-2" />
              <select
                name="ubicacion"
                value={formData.ubicacion}
                onChange={handleSelectUbicacion}
                className="w-full outline-none"
              >
                <option value="GUAYAQUIL">GUAYAQUIL</option>
                <option value="CUENCA">CUENCA</option>
                <option value="QUITO">QUITO</option>
              </select>
            </div>
            <p className="text-gray-700 text-sm mt-1">Seleccione una ubicación</p>
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition-colors font-semibold"
          >
            Enviar a WhatsApp
          </button>
          {!isFormValid() && (
            <p className="text-red-500 text-center mt-4">Por favor, completa todos los campos.</p>
          )}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default AfiliatedMororized;
