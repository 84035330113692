import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faCheckCircle, faUsersViewfinder, faMoneyBillTransfer, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import deverlyEntrega from '../../images/deverly-entrega.png';
import tarifaRecorrido from '../../images/tarifa-recorrido.png';
import deverlyPointMap from '../../images/gifs/deverly-point-map.gif';
import '../../../css/CardComponent.css';

const cardsData = [
  { id: 1, title: '¿Quiénes somos?', icon: faCircleQuestion },
  { id: 2, title: 'Nuestros servicios', icon: faUsersViewfinder },
  { id: 3, title: 'Nuestras tarifas de recorrido', icon: faMoneyBillTransfer },
];

const CardContent = ({ card }) => {
  const content = {
    1: (
      <>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8 items-center"> {/* Asegurando que el texto esté alineado al centro en pantallas grandes */}
          <div className="text-gray-800 px-2 md:px-4 text-center lg:text-left"> 
            <h2 className="lg:text-4xl text-center mb-11 text-[#412CC5] animate-fadeIn"><b>¿Quiénes somos?</b></h2>
            <ul className="list-disc list-inside mt-4 pl-4 lg:pl-6 space-y-4 animate-fadeIn">
              <li className='text-justify list-none'>
                Somos una empresa creada como emprendimiento en base a las necesidades que surgieron para apoyar a los negocios, a partir de la Pandemia en 2020 por el Covid-19. Surgimos ante la obligación de encontrar la manera de conectar a través del servicio a domicilio para el consumidor final.
              </li>
            </ul>
          </div>
          <div className="flex justify-center items-center"> 
            <img
              src={deverlyEntrega}
              alt="Deverli Entrega"
              className="w-full h-auto max-w-xs md:max-w-sm lg:max-w-md rounded-lg shadow-lg animate-smoothInfinite"
            />
          </div>
        </div>
      </>
    ),
    2: (
      <>
      <h2 className="text-center mb-4 text-[#412CC5] animate-fadeIn">
        <b>La Plataforma Tecnológica que Revoluciona tu Logística</b>
      </h2>
      <div className="grid grid-cols-1 items-center max-w-full overflow-x-auto">
        <div className="text-gray-800 px-2 md:px-4">
          <ul className="list-none space-y-4 text-sm md:text-base lg:text-lg animate-fadeIn">
            <li className="flex items-center w-full">
              <FontAwesomeIcon icon={faCheckCircle} className="text-[#F15A24] mr-2 md:text-xl" />
              <span>Integración con <b className='text-[#412CC5]'><a href="https://fast-api-deverly-plus.onrender.com/" target='_blank' rel="noopener noreferrer">API REST</a></b> para una conectividad rápida y eficiente en cualquier entorno de programación.</span>
            </li>
            <li className="flex items-center">
              <FontAwesomeIcon icon={faCheckCircle} className="text-[#F15A24] mr-2 md:text-xl" />
              <span> Optimiza la <b className='text-[#412CC5] '>velocidad y precisión</b> en las entregas, maximizando la satisfacción del cliente.</span>
            </li>
            <li className="flex items-center">
              <FontAwesomeIcon icon={faCheckCircle} className="text-[#F15A24] mr-2 md:text-xl" />
              <span><b className='text-[#412CC5] '>Geolocalización avanzada</b> que garantiza la seguridad y seguimiento en tiempo real de todos tus envíos, brindando tranquilidad.</span>
            </li>
            <li className="flex items-center">
              <FontAwesomeIcon icon={faCheckCircle} className="text-[#F15A24] mr-2 md:text-xl" />
              <span> <b className='text-[#412CC5] '>Soporte de Call Center</b> dedicado para ofrecer una atención al cliente excepcional y personalizada, asegurando que todas tus dudas sean resueltas.</span>
            </li>
            <li className="flex items-center"> 
              <FontAwesomeIcon icon={faCheckCircle} className="text-[#F15A24] mr-2 md:text-xl" />
              <span>Servicio <b className='text-[#412CC5] '>puerta a puerta</b> que asegura la comodidad de recibir tus pedidos directamente en tu hogar, sin complicaciones.</span>
            </li>
            <li className="flex items-center"> 
              <FontAwesomeIcon icon={faInfoCircle} className="text-[#F15A24] mr-2 md:text-xl" />
              <span> Disponibilidad en las principales ciudades del Ecuador: <b className='text-[#412CC5] '>Guayaquil, Cuenca, Quito y Quevedo.</b></span>
            </li>
          </ul>
        </div>
        <div className="flex justify-center items-center overflow-hidden"> {/* Cambiado justify-start a justify-center */}
            <img
              src={deverlyPointMap}
              alt="Ubícanos en"
              className="w-full h-auto max-w-xs md:max-w-sm lg:max-w-md rounded-lg shadow-lg animate-smoothInfinite"
            />
          </div>
      </div>
    </>
    ),
    3: (
      <>
        <h2 className="lg:text-3xl text-center mb-4 text-[#412CC5] animate-fadeIn">Nuestras Tarifas</h2>
        <div className="grid grid-cols-1 ">
          <table className='text-sm md:text-base lg:text-lg text-center w-full border border-gray-300 animate-fadeIn rounded-lg'>
            <thead className="bg-[#412CC5] text-white">
              <tr>
                <th className="border border-gray-300 p-2">Tipo de Servicio</th>
                <th className="border border-gray-300 p-2">
                  <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-[#ffffff] mr-2 text-lg md:text-xl" />
                  Tarifa
                </th>
                <th className="border border-gray-300 p-2">
                  <FontAwesomeIcon icon={faInfoCircle} className="text-[#ffffff] mr-2 text-lg md:text-xl" />
                  Información
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className='bg-[#fdbba1]'>
                <td className="border border-gray-300 p-2">Servicio Express</td>
                <td className="border border-gray-300 p-2">$4.00</td>
                <td className="border border-gray-300 p-2">Entrega en el menor tiempo posible</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Servicio Programado</td>
                <td className="border border-gray-300 p-2">Variable</td>
                <td className="border border-gray-300 p-2 text-justify">Entrega en el tiempo en el cual tu requieres</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Servicio Mínimo</td>
                <td className="border border-gray-300 p-2">$2.50</td>
                <td className="border border-gray-300 p-2 text-justify">Entrega de productos de acuerdo a la asignación de disponibilidad</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Compramos por ti</td>
                <td className="border border-gray-300 p-2">$2.00 (incluye costo adicional de los productos)</td>
                <td className="border border-gray-300 p-2 text-justify">
                  Realizamos la compra por ti, solo dinos los que necesitas 
                  y lo llevaremos a la comodidad de tu hogar
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex justify-center items-center"> {/* Cambiado justify-start a justify-center */}
            <img
              src={tarifaRecorrido}
              alt="Tarifas"
              className="w-full h-auto max-w-xs md:max-w-sm lg:max-w-md rounded-lg animate-smoothInfinite"
            />
          </div>
        </div>
      </>
    ),
  };

  return content[card.id] || null;
};

const CardComponent = () => {
  const [selectedCard, setSelectedCard] = useState(cardsData[0].id);

  return (
    <div className="w-full p-2 md:p-4 lg:p-8 max-w-screen-lg mx-auto">
      <h1 className="text-center text-[#412CC5] mb-4 md:mb-8 text-4xl  "><b>Conoce más de nosotros</b></h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 md:mb-6 lg:mb-8">
        {cardsData.map((card) => (
          <div
            key={card.id}
            className={`flex items-center p-4 bg-white border border-gray-200 rounded-lg shadow-lg transition-transform transform duration-300 hover:scale-105 cursor-pointer ${
              selectedCard === card.id ? 'bg-blue-50 border-blue-500 shadow-xl' : 'hover:border-blue-300'
            }`}
            onClick={() => setSelectedCard(card.id)}
          >
            <FontAwesomeIcon
              icon={card.icon}
              className={`text-xl md:text-2xl lg:text-3xl mr-2 md:mr-4 ${
                selectedCard === card.id ? 'text-[#F15A24]' : 'text-gray-600'
              }`}
            />
            <h2
              className={`text-base md:text-lg lg:text-xl ${
                selectedCard === card.id ? 'text-[#412CC5]' : 'text-gray-800'
              }`}
            >
              {card.title}
            </h2>
          </div>
        ))}
      </div>
      <section className="p-4 md:p-6 lg:p-8 bg-white border border-gray-200 rounded-lg shadow-md relative">
        <CardContent card={cardsData.find((card) => card.id === selectedCard)} />
      </section>
    </div>
  );
};

export default CardComponent;
