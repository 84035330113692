
import ButtonNavigator from "./home/ButtonNavigator";
import CardComponent from "./home/CardComponent";
import CarouselClients from "./home/CarouselClients";
import Footer from "./home/Footer";
import Navbar from "./home/NavBar";
import WhyPreferme from "./home/WhyPreferme";
import Contact from "./home/Contact";
import ExpandCoberture from "./home/ExpandCoberture";
import HomeScreen from "./home/HomeScreen";
import DeliveryFeatures from "./home/DeliveryFeatures";
import StatsFeatures from "./home/StatsFeatures";
import ComparisonComponent from "./home/ComparisonComponent";
import InformationApi from "./home/InformationApi";

function HomePage() {
  return (
    <div className="App overflow-hidden">
      <Navbar />
      <header className="App-header">
        <HomeScreen />
      </header>
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a
            className="text-white bg-[#F15A24] px-8 py-4 sm:px-10 sm:py-5 font-bold rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:bg-[#da5727] shadow-md hover:shadow-xl duration-300"
            href='/form-afiliated-commerce'
          >
            Únete como comercio
          </a>
          <a
            className="text-white bg-[#F15A24] px-8 py-4 sm:px-10 sm:py-5 font-bold rounded-lg flex items-center justify-center transition-transform transform hover:scale-105 hover:bg-[#da5727] shadow-md hover:shadow-xl duration-300"
            href='/form-afiliated-motorized'
          >
            Afíliate como Motorizado
          </a>
        </div>
      </div>
      <CardComponent />
      <WhyPreferme />
      <InformationApi />
      {/* <BenefitsWorking /> */}
      <DeliveryFeatures/>
      <ExpandCoberture />
      <ComparisonComponent />
      <Contact />
      <StatsFeatures />
      <CarouselClients />
      <Footer />
      <ButtonNavigator />
    </div>
  );
}

export default HomePage;