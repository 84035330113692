import React from "react";
import CubeComponent from "./CubeComponent";
import '../../../css/CardComponent.css';

const ExpandCoberture = () => {
    return (
        <section className="w-full bg-white py-10 px-4 lg:px-8 max-w-6xl mx-auto">
            <div className="flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center">
                    <CubeComponent />
                </div>
                <div className="w-full md:w-1/2 md:pl-8">
                    <div className="text-info-map mb-8 text-center md:text-left">
                        <h2 className="text-4xl md:text-3xl font-bold mb-4 text-[#412CC5]">
                            <b>Amplía el alcance de tus entregas y aumenta tus ventas.</b>
                        </h2>
                        <p className="text-base md:text-lg mb-8 text-justify">
                            
                        ¿Sabías que nuestros clientes ven un <strong> aumento </strong> de hasta un<strong> 50% </strong>en sus ventas y <strong>reducen</strong> hasta un <strong>70% </strong>en costos de envío al utilizar <strong className="text-[#F15A24]">Deverly Plus</strong>? ¡Descubre cómo optimizar tus entregas y maximizar tus ganancias con nosotros!
                        </p>
                    </div>
                    <div className="flex justify-center md:justify-start">
                        <a
                            href="/from-mi-primer-delivery"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white bg-[#F15A24] px-6 py-3 font-bold rounded-lg transition-transform transform hover:scale-105 hover:bg-[#da5727] hover:shadow-lg shadow-md duration-300"
                        >
                            Solicita tu delivery
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExpandCoberture;
