import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ScrollToTopButton from '../../functions/ScrollToTopButton'; // Corrige la ruta según corresponda
import SendMessage from '../forms/SendMenssage'; // Corrige la ruta según corresponda

const ButtonNavigator = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50 flex flex-col space-y-4">
      <button
        onClick={openModal}
        className="bg-[#32c019] text-white p-4  rounded-full shadow-lg hover:bg-[#0d8b0d] transition-colors duration-300"
        title="Enviar mensaje por WhatsApp"
        aria-label="Enviar mensaje por WhatsApp"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="text-2xl" />
      </button>
      <ScrollToTopButton />
      <SendMessage isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div>
  );
};

export default ButtonNavigator;
