import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faShop, faWarning } from "@fortawesome/free-solid-svg-icons";
import { faUser, faPhone, faMapMarkerAlt, faStore } from '@fortawesome/free-solid-svg-icons';
import Navbar from "../home/NavBar";
import Footer from "../home/Footer";
import SendMessagesWhatp from "../../functions/SendMessagesWhatp";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

// Configurar iconos de Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: '90vw', // Ajustar el tamaño del modal para pantallas pequeñas
    maxHeight: '80vh' // Ajustar la altura del modal para pantallas pequeñas
  },
};

Modal.setAppElement("#root");

const SearchField = () => {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      style: "bar",
      autoComplete: true,
      autoCompleteDelay: 250,
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, [map]);
  return null;
};

const LocationMarker = ({ onSelectLocation }) => {
  const [position, setPosition] = useState(null);

  useMapEvents({
    click(e) {
      setPosition(e.latlng);
      onSelectLocation(e.latlng);
    },
  });

  return position ? <Marker position={position}></Marker> : null;
};

const MapComponent = ({ onSelectLocation }) => {
  const initialPosition = { lat: -0.2201739495257804, lng: -78.51232409477235 };

  return (
    <MapContainer center={initialPosition} zoom={13} style={{ height: "400px", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker onSelectLocation={onSelectLocation} />
      <SearchField />
    </MapContainer>
  );
};

const AfiliatedCommerce = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [formData, setFormData] = useState({
    nombreComercio: "",
    nombres: "",
    apellidos: "",
    telefono: "",
    ubicacion: "GUAYAQUIL"
  });
  const [phoneError, setPhoneError] = useState(false);

  const isFormValid = () => {
    return formData.nombreComercio && formData.nombres && formData.apellidos && formData.ubicacion !== "" && isValidPhoneNumber(formData.telefono);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const getMessage = () => {
    return `Hola, quiero afiliar mi comercio a Delivery Plus
    \nNombre del Comercio: ${formData.nombreComercio}
    \nNombres: ${formData.nombres}
    \nApellidos: ${formData.apellidos}
    \nTeléfono: ${formData.telefono}
    \nUbicación: ${formData.ubicacion}`;
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSelectLocation = (latlng) => {
    setSelectedLocation(latlng);
    setFormData({ ...formData, location: JSON.stringify(latlng) });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, telefono: value || '' });
    setPhoneError(!isValidPhoneNumber(value || ''));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      console.log("Formulario enviado");
    } else {
      alert("Por favor, completa todos los campos.");
    }
  };

  const handleSelectUbicacion = (e) => {
    const selectedUbicacion = e.target.value;
    setFormData({ ...formData, ubicacion: selectedUbicacion });
  };

  return (
    <>
      <Navbar regresar={true} />
      <div className="flex justify-center items-center min-h-screen bg-[#412CC5] py-8 px-4 sm:px-6 lg:px-8">
        <form className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4" onSubmit={handleSubmit}>
          <h1 className="text-2xl sm:text-3xl font-bold text-[#412CC5] text-center mb-4">
            <FontAwesomeIcon icon={faShop} className="mr-2" />
            Afiliar Comercio
          </h1>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Nombre del Comercio</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faStore} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="nombreComercio"
                value={formData.nombreComercio}
                onChange={handleInputChange}
                className="w-full outline-none"
                placeholder="Nombre del Comercio"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Nombres</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faUser} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="nombres"
                value={formData.nombres}
                onChange={handleInputChange}
                className="w-full outline-none"
                placeholder="Nombres"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Apellidos</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faUser} className="text-[#412CC5] mr-2" />
              <input
                type="text"
                name="apellidos"
                value={formData.apellidos}
                onChange={handleInputChange}
                className="w-full outline-none"
                placeholder="Apellidos"
                required
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-semibold">Teléfono</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faPhone} className="text-[#412CC5] mr-2" />
              <PhoneInput
                international
                defaultCountry="EC"
                value={formData.telefono}
                onChange={handlePhoneChange}
                className={`w-full outline-none ${phoneError ? 'border-red-500' : 'border-gray-300'}`}
              />
            </div>
            {phoneError && <p className="text-red-500 text-sm mt-1">Número de teléfono inválido</p>}
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 font-semibold">Ubicación</label>
            <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#412CC5] mr-2" />
              <select
                name="ubicacion"
                value={formData.ubicacion}
                onChange={handleSelectUbicacion}
                className="w-full outline-none"
              >
                <option value="GUAYAQUIL">GUAYAQUIL</option>
                <option value="CUENCA">CUENCA</option>
                <option value="QUITO">QUITO</option>
              </select>
            </div>
            <p className="text-gray-700 text-sm mt-1">Seleccione una ubicación</p>

          </div>
          {selectedLocation ? (
            <>
              <p className="mb-4">
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" /> Ubicación seleccionada
              </p>
              <button
                type="button"
                onClick={openModal}
                className="w-full bg-[#412CC5] text-white p-2 mb-4 rounded-lg hover:bg-[#2d1f7c]"
              >
                Seleccionar otra Ubicación
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={openModal}
                className="w-full bg-[#c52c43] text-white p-2 mb-4 rounded-lg"
              >
                Seleccionar en Mapa
              </button>
              <p className="text-center text-[#ee800b]">
                <FontAwesomeIcon icon={faWarning} className="mr-2" />
                No has seleccionado una ubicación en el mapa
              </p>
            </>
          )}
          {(isFormValid() && selectedLocation) ? (
            <SendMessagesWhatp message={getMessage()} sendlocation={true} location={selectedLocation}/>
          ) : (
            <p className="text-red-500 text-center">Por favor, completa todos los campos.</p>
          )}
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Selecciona una ubicación"
      >
        <h2 className="text-center font-bold text-[#412CC5] mb-4">Selecciona una Ubicación</h2>
        <MapComponent onSelectLocation={handleSelectLocation} />
        <div className="flex justify-center mt-4 gap-2">
          <button
            onClick={closeModal}
            className="bg-[#412CC5] text-white p-2 rounded-lg hover:bg-[#2d1f7c]"
          >
            Cerrar
          </button>
          <button
            onClick={closeModal}
            className="bg-[#4dc113] text-white p-2 rounded-lg hover:bg-[#20711d]"
          >
            Seleccionar
          </button>
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default AfiliatedCommerce;
